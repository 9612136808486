<script lang="ts" setup>
import navItems from '@/navigation/vertical'

// Components
import NavBarNotifications from '@/layouts/components/NavBarNotifications.vue'
import Reload from '@/layouts/components/Reload.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'
import Footer from '@/views/front-pages/front-page-footer.vue'

// @layouts plugin
import { VerticalNavLayoutLanding } from '@layouts'

const userData = useCookie<any>('userData')
const isDrawerVisible = ref(false)
</script>

<template>
  <VerticalNavLayoutLanding :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <IconBtn
          id="vertical-nav-toggle-btn"
          class="ms-n3 d-lg-none"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon
            size="26"
            icon="tabler-menu-2"
          />
        </IconBtn>

        <VSpacer />

        <Reload />

        <div
          v-if="userData"
          class="d-flex"
        >
          <VBtn
            to="/help"
            variant="plain"
            color="#2F2B3D"
          >
            <VIcon
              size="26"
              icon="tabler-help"
              class="d-sm-none"
            />
            <span class="d-none d-sm-block ms-2">ヘルプ</span>
          </VBtn>
          <NuxtLink
            v-if="userData.role !== 'admin' "
            :to="{ name: userData.role === 'client' ? 'clients-calendar' : 'owners-calendar' }"
          >
            <IconBtn>
              <VIcon
                size="26"
                icon="tabler-calendar"
              />
            </IconBtn>
          </NuxtLink>
          <NavBarNotifications />
          <UserProfile v-model:isDrawerOpen="isDrawerVisible" />
        </div>
        <div
          v-else
          class="d-flex gap-x-4"
        >
          <NuxtLink :to="{ name: 'member' }">
            <VBtn
              variant="outlined"
              color="primary"
              rounded="pill"
            >
              <VIcon
                icon="tabler-user-plus"
                class="d-sm-none"
              />
              <span class="d-none d-sm-block ms-2">無料会員登録</span>
            </VBtn>
          </NuxtLink>

          <NuxtLink :to="{ name: 'login' }">
            <VBtn
              variant="elevated"
              rounded="pill"
              color="primary"
            >
              <VIcon
                icon="tabler-login"
                class="d-sm-none"
              />
              <span class="d-none d-sm-block ms-2">ログイン</span>
            </VBtn>
          </NuxtLink>
        </div>
      </div>
    </template>
    <slot />

    <template #footer>
      <Footer />
    </template>

    <TheCustomizer v-model:isDrawerOpen="isDrawerVisible" />
  </VerticalNavLayoutLanding>
</template>
